import React, { useMemo, useCallback } from 'react';
import omit from 'lodash/omit';
import useMediaQuery from '@mui/material/useMediaQuery';

import PageWrapper from 'components/Globals/Layout/PageWrapper';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import BannerTestTrusted from 'components/Home/BannerTestTrusted';
import { useOrgIndexPageFiltersMeta } from 'components/Organization/Display/IndexPageFilters';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';

import { TP, ENTITY_TYPE } from 'constants/index';
import { FILTER_SLUG_TYPE, FILTER_TYPES } from 'constants/filters';

import { useTranslation } from 'src/i18n';

import classes from './BaseWrapper.module.scss';

const useBaseWrapperBreadcrumbs = (breadcrumbProps = {}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entityType, filters } = usePageContext();
  const orgIndexFiltersMeta = useOrgIndexPageFiltersMeta();

  const getBreadcrumbItem = useCallback(
    ({ title, props, skipLink = false }) => {
      if (skipLink) {
        return {
          title,
        };
      }

      const { as, href, url } = navigate.getLinkProps(props);

      return {
        as,
        href,
        title,
        url,
      };
    },
    [navigate],
  );

  const breadcrumbs = useMemo(() => {
    const { selectedProfession } = breadcrumbProps;
    const items = new Set();

    items.add(getBreadcrumbItem({ title: `${TP}.m_HOME` }));

    if (entityType === ENTITY_TYPE.ARTIST) {
      items.add(getBreadcrumbItem({ title: `${TP}.m_ARTISTS`, props: { entityType } }));
    } else if (entityType === ENTITY_TYPE.ORGANIZATION) {
      items.add(getBreadcrumbItem({ title: `${TP}.FN_ARTS_ORGANISATIONS`, props: { entityType } }));

      const { selectedOrgType, selectedCountry, selectedCity } = orgIndexFiltersMeta;

      const countryFilterSlug = FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY];
      const cityFilterSlug = FILTER_SLUG_TYPE[FILTER_TYPES.CITY];
      const pageFilterSlug = FILTER_SLUG_TYPE[FILTER_TYPES.PAGE];
      const letterFilterSlug = FILTER_SLUG_TYPE[FILTER_TYPES.LETTER];

      if (selectedOrgType) {
        items.add(
          getBreadcrumbItem({
            title: selectedOrgType?.name,
            props: {
              entityType,
              filters: omit(filters, [countryFilterSlug, cityFilterSlug, pageFilterSlug, letterFilterSlug]),
            },
          }),
        );
      }

      if (selectedCountry) {
        items.add(
          getBreadcrumbItem({
            title: selectedCountry?.name,
            props: { entityType, filters: omit(filters, [cityFilterSlug, pageFilterSlug, letterFilterSlug]) },
          }),
        );
      }

      if (selectedCity) {
        items.add(
          getBreadcrumbItem({
            title: selectedCity?.name,
            props: { entityType, filters: omit(filters, [pageFilterSlug, letterFilterSlug]) },
          }),
        );
      }
    }

    if (selectedProfession?.slug) {
      items.add(
        getBreadcrumbItem({ title: selectedProfession?.name, props: { entityType, path: selectedProfession?.slug } }),
      );
    }

    if (filters?.page) {
      items.add(getBreadcrumbItem({ title: `${t(`${TP}.FN_PAGE`)} ${filters?.page}`, skipLink: true }));
    }

    return items;
  }, [getBreadcrumbItem, entityType, breadcrumbProps, t, orgIndexFiltersMeta, filters]);

  return [...breadcrumbs];
};

const BaseWrapper = ({ seo, children, breadcrumbProps }) => {
  const { entityType, hasAppliedFilters } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isTablet = useMediaQuery('(max-width:1024px)');
  const breadcrumbs = useBaseWrapperBreadcrumbs(breadcrumbProps);
  const isHeaderStatic = [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) && !isMobile && !isTablet;

  return (
    <PageWrapper seo={seo} isHeaderStatic={isHeaderStatic}>
      <div className={classes.root}>
        {!hasAppliedFilters && <BannerTestTrusted />}
        <div className={classes.root__breadcrumbs}>
          <Breadcrumbs data={breadcrumbs} {...(!!isMobile && { maxItems: 2 })} />
        </div>
        {children}
      </div>
    </PageWrapper>
  );
};

export default BaseWrapper;
